// src/AlbumModal.js
import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Image,
  Box,
  Text,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
  IconButton,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { UpDownIcon } from '@chakra-ui/icons';
import { MdOutlineVolumeUp, MdOutlineLyrics, MdShare } from "react-icons/md";
import { HiPlayPause } from "react-icons/hi2";
import { ImLoop } from "react-icons/im";
import LyricsDrawer from '../LyricsDrawer';
import { updateURL } from '../utils/utils';
import { useParams } from 'react-router-dom';

const AlbumModal = ({ isAlbumOpen, onAlbumClose, album, albumDetails }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volumeOpen, setVolumeOpen] = useState(false);
  const [currentVolume, setCurrentVolume] = useState(0);
  const [loop, setLoop] = useState(0); // 0 for disabled, 1 for album, 2 for song
  const [loopColor, setLoopColor] = useState('blackAlpha');
  const [songEnded, setSongEnded] = useState(false);
  const [trackLyrics, setTrackLyrics] = useState(null);
  const [shareBtnVis, setShareBtnVis] = useState(null);
  const audioRef = useRef(null);
  const toast = useToast();
  const [resetLyrics, setResetLyrics] = useState(false);
  const [clickedCopyBtn, setClickedCopyBtn] = useState(false);
  const { trackParam } = useParams();
  const [trackNumber, setTrackNumber] = useState (trackParam);
  const [loadFromUrl, setLoadFromUrl] = useState(false);

  useEffect(() => {
    if (loadFromUrl && trackNumber) {
      const track = albumDetails.tracks[trackParam - 1];
      const index = trackParam - 1
      setCurrentIndex(index);
      setCurrentTrack(track);
      handleSeek(0);
      if (track?.lyrics.length)
        setTrackLyrics(track.lyrics);
      else
        setTrackLyrics(undefined);
      setResetLyrics(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFromUrl]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.ontimeupdate = () => setCurrentTime(audioRef.current.currentTime);
      audioRef.current.onloadedmetadata = () => setDuration(audioRef.current.duration);
      audioRef.current.onended = () => handleOnSongEnd();
      setCurrentVolume(audioRef.current.volume);
    }
  }, [currentTrack]);

  useEffect(() => {
    if (trackParam && album && albumDetails) {
      setLoadFromUrl(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumDetails])

  useEffect(() => {
    if (songEnded) {
      const nextTrack = albumDetails.tracks[currentIndex+1];
      if (loop === 0) {
        if (nextTrack) {
          setCurrentTrack(nextTrack);
          setCurrentIndex(currentIndex+1);
          if (nextTrack?.lyrics)
           setTrackLyrics(nextTrack.lyrics);
          else {
            setTrackLyrics(undefined);
            onClose()
          }
          setIsPlaying(true);
          updateURL(`${nextTrack?.number}`, 
            {additionalInformation: 'Pushing song history'},
            true
          );
          toast({
            title: `Now playing: ${nextTrack.name}`,
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        }
      } else if (loop === 1) {
        if (nextTrack) {
          setCurrentTrack(nextTrack);
          setCurrentIndex(currentIndex+1);
          if (nextTrack?.lyrics)
            setTrackLyrics(nextTrack.lyrics);
          setIsPlaying(true);
          updateURL(`${nextTrack?.number}`, 
            {additionalInformation: 'Pushing song history'},
            true
          );
          toast({
            title: `Now playing: ${nextTrack.name}`,
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        } else {
          setCurrentTrack(albumDetails.tracks[0]);
          setCurrentIndex(0);
          if (albumDetails.tracks[0]?.lyrics)
            setTrackLyrics(albumDetails.tracks[0].lyrics);
          setIsPlaying(true);
          updateURL(`${albumDetails.tracks[0].number}`, 
            {additionalInformation: 'Pushing song history'},
            true
          );
          toast({
            title: `Now playing: ${albumDetails.tracks[0].name}`,
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        }
      } else if (loop === 2) {
        setCurrentTrack(currentTrack);
        setCurrentTime(0);
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        setIsPlaying(true);
        toast({
          title: `Repeating: ${currentTrack.name}`,
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      }
      setSongEnded(false);
    }
    //eslint-disable-next-line
  }, [songEnded]);

  useEffect(() => {
    if (clickedCopyBtn) {
      setTimeout(() => {
        setClickedCopyBtn(false);
      }, 500);
    }
  }, [clickedCopyBtn])

  useEffect(() => {
    if (!isAlbumOpen && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      setCurrentTime(0);
      setCurrentTrack(null);
      setTrackLyrics(null);
    }
  }, [isAlbumOpen]);

  const handleTrackClick = (track, index) => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    setCurrentIndex(index);
    setCurrentTrack(track);
    handleSeek(0);
    audioRef?.current?.play();
    setIsPlaying(true);
    if (track.lyrics.length)
      setTrackLyrics(track.lyrics);
    else
      setTrackLyrics(undefined);
    setResetLyrics(true);
    updateURL(`${track?.number}`, 
      {additionalInformation: 'Pushing song history'},
      true
    );
    toast({
      title: `Now playing: ${track.name}`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleVolumeControl = () => {
    setVolumeOpen(!volumeOpen);
  }

  const handleLoopChange = () => {
    if (loopColor === 'blackAlpha') {
      setLoopColor('teal');
      setLoop(1);
    } else if (loopColor === 'teal') {
      setLoopColor('yellow');
      setLoop(2);
    } else {
      setLoopColor('blackAlpha');
      setLoop(0);
    }
  }

  const handleShareClick = () => {
    setShareBtnVis(!shareBtnVis);
  }

  const handleVolumeChange = (value) => {
    if (audioRef.current) {
      audioRef.current.volume = value / 100;
      setCurrentVolume(value / 100);
    }
  }

  const handleSeek = (value) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleSliderSeek = (value) => {
    handleSeek(value);
    setResetLyrics(true);
  }

  const handleOnSongEnd = () => {
    setIsPlaying(false);
    setSongEnded(true);
  }

  const handleLyricsButtonClick = () => {
    onOpen();
  }

  const handleAlbumClose = () => {
    updateURL(`/`, 
      {additionalInformation: 'Resetting'},
      true
    );
    setTrackNumber(null);
    setShareBtnVis(false);
    setLoadFromUrl(false);
    onAlbumClose();
  }

  const handleCopyBtnClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setClickedCopyBtn(true);
  }

  return (
    <>
      <Modal isOpen={isAlbumOpen} onClose={handleAlbumClose} size="xl" blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{album?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Image
                src={albumDetails.cover}
                alt={`${album?.name} cover`}
                boxSize="200px"
                borderRadius="md"
              />
              <Box w="100%">
                <Heading as="h3" size="md" mt={4} mb={2} textAlign="left">
                  Track List
                </Heading>
                {albumDetails.tracks.map((track, index) => (
                  <Text
                    key={index}
                    textAlign="left"
                    cursor="pointer"
                    _hover={{ color: "teal.500" }}
                    onClick={() => handleTrackClick(track, index)}
                  >
                    {track.number}. {track.name}
                  </Text>
                ))}
              </Box>
            </VStack>
          </ModalBody>
          {currentTrack && (
            <>
              <Box p={4} w="100%">
                <Box display='flex' alignContent='center'>
                  <audio
                    ref={audioRef}
                    src={`/api/album/${album?.year}/${album?.folder}/${currentTrack.file}`}
                    style={{ display: 'none' }}
                    autoPlay
                  />
                  <IconButton
                    aria-label='Play/pause'
                    colorScheme={isPlaying ? 'cyan' : 'blackAlpha'}
                    icon={<HiPlayPause />}
                    onClick={() => togglePlayPause()}
                  />
                  <Box paddingLeft={2}>
                    <IconButton
                      aria-label='Loop'
                      colorScheme={loopColor}
                      icon={<ImLoop />}
                      onClick={() => handleLoopChange()}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} paddingLeft={2} w="100%">
                    <IconButton
                      aria-label='Volume control'
                      colorScheme='orange'
                      icon={<UpDownIcon />}
                      onClick={() => toggleVolumeControl()}
                    />
                    {volumeOpen && (
                      <Box display={'flex'} paddingLeft={4} w="60%">
                        <Slider
                          aria-label='slider-ex-4'
                          defaultValue={currentVolume * 100}
                          onChange={handleVolumeChange}
                        >
                          <SliderTrack bg='red.100'>
                            <SliderFilledTrack bg='tomato' />
                          </SliderTrack>
                          <SliderThumb boxSize={6}>
                            <Box color='tomato' as={MdOutlineVolumeUp} />
                          </SliderThumb>
                        </Slider>
                      </Box>
                    )}
                  </Box>
                  {currentTrack && (<Box paddingLeft={2} paddingRight={2}>
                    <IconButton
                      aria-label='Loop'
                      colorScheme='blue'
                      rounded='full'
                      icon={<MdShare />}
                      onClick={() => handleShareClick()}
                    />
                  </Box>)}
                  {trackLyrics && (<Box>
                    <IconButton
                      aria-label='Lyrics'
                      colorScheme='green'
                      icon={<MdOutlineLyrics />}
                      onClick={() => handleLyricsButtonClick()}
                    />
                  </Box>)}
                </Box>
                {shareBtnVis && (
                  <>
                    <Box paddingTop={3} justifyContent="space-around">
                      <Input marginRight={6} disabled w="80%" variant="filled" value={window.location.href} />
                      <Button 
                        w="15%"
                        size="md"
                        variant="outline"
                        colorScheme={clickedCopyBtn ? "gray" : "purple"}
                        onClick={() => handleCopyBtnClick()}
                      >{clickedCopyBtn ? 'Copied' : 'Copy'}</Button>
                    </Box>
                  </>
                )}
                <Slider
                  value={currentTime}
                  min={0}
                  max={duration}
                  onChange={handleSliderSeek}
                  mt={3}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Box>         
            </>
          )}
          <ModalFooter>
            <Button onClick={handleAlbumClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <LyricsDrawer
        isLDOpen={isOpen}
        onLDClose={onClose}
        lyrics={trackLyrics}
        blockScrollOnMount={false}
        currentTime={currentTime}
        handleSeek={handleSeek}
        songEnded={songEnded}
        resetLyrics={resetLyrics}
        setResetLyrics={setResetLyrics}
      />
    </>
  );
};

export default AlbumModal;
