export const timeStringToSeconds = (timeString) => {
    const [minutesPart, secondsPart] = timeString.split(':').map(Number);
    const minutes = parseFloat(minutesPart);
    const seconds = parseFloat(secondsPart); // Automatically handles "seconds.ms"
    return parseFloat((minutes * 60) + seconds);
}

export const isPastTime = (timeInSeconds, timeString) => {
    if (timeString) {
        const targetTimeInSeconds = timeStringToSeconds(timeString);
        return timeInSeconds > targetTimeInSeconds;
    }
    return false;
}

export const updateURL = (url, state, replace = false) =>
    replace
      ? window.history.replaceState(state, '', url)
      : window.history.pushState(state, '', url);