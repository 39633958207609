// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:yearParam" element={<HomePage />} />
        <Route path="/:yearParam/:cdParam" element={<HomePage />} />
        <Route path="/:yearParam/:cdParam/:trackParam" element={<HomePage />} />
      </Routes>
    </Router>
  )
};

export default App;
