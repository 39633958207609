// src/Album.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Text,
  VStack,
  Image,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import './styles.css';
import AlbumModal from '../AlbumModal';
import { updateURL } from '../utils/utils';
import { useParams } from 'react-router-dom';

const Album = (props) => {
  const { albums, year, maxAlbumsPerRow } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [albumDetails, setAlbumDetails] = useState({ cover: '', tracks: [] });
  const { yearParam, cdParam } = useParams();

  const getAlbumFromUrl = () => {
    if(year === yearParam) {
      let detectedAlbum = albums.filter((album) => (album.cdNumber === cdParam));
      setSelectedAlbum({ ...detectedAlbum[0], year});
      fetchAlbumDetails(year, detectedAlbum[0].folder);
      onOpen()
    }
  };

  useEffect(() => {
    if (yearParam && cdParam) {
      getAlbumFromUrl();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchAlbumDetails = async (year, folder) => {
    const response = await fetch(`/api/album/${year}/${folder}`);
    const data = await response.json();
    setAlbumDetails(data);
  };

  const handleAlbumClick = (album) => {
    setSelectedAlbum({ ...album, year });  // Include the year here
    fetchAlbumDetails(year, album.folder);
    updateURL(`/${year}/${album.cdNumber}/`, 
          {additionalInformation: 'Pushing album and year into history'},
          true
        );
    onOpen();
  };

  const handleAlbumClose = () => {
    setAlbumDetails({ cover: '', tracks: [] });
    setSelectedAlbum(null);
    onClose();
  };

  return (
    <Box mb={10}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={5}>
        <Heading as="h2" size="lg" textAlign="center" mb={5} color="teal.400">
          /v/ the Musical {year}
        </Heading>
        <IconButton
          as="a"
          href={props.download}
          aria-label={`Download ${year}`}
          icon={<DownloadIcon />}
          size="sm"
          ml={3}
          colorScheme="teal"
          target="_blank" 
          rel="noopener noreferrer"
        />
      </Box>
      {albums.reduce((rows, album, index) => {
        if (index % maxAlbumsPerRow === 0) rows.push([]);
        rows[rows.length - 1].push(album);
        return rows;
      }, []).map((row, rowIndex) => (
        <Box key={rowIndex} display="flex" justifyContent="center" mb={6}>
          <Grid
            templateColumns={`repeat(${row.length}, 1fr)`}
            gap={6}
            justifyContent="center"
            maxWidth={`${maxAlbumsPerRow * 240}px`}
          >
            {row.map((album) => (
              <GridItem key={album.folder} onClick={() => handleAlbumClick(album)}>
                <VStack className="album-container">
                  <Image className="album-image" src={album.cover} alt={`${album.name} cover`} />
                  <Text className="album-name">{album.name}</Text>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </Box>
      ))}

      <AlbumModal isAlbumOpen={isOpen} onAlbumClose={handleAlbumClose} album={selectedAlbum} albumDetails={albumDetails} blockScrollOnMount={false}/>
    </Box>
  );
};

export default Album;
